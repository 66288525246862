import { ShopDetailI } from '@/store/modules/shop/state'
import { store } from '@/store'
import { AxiosResponse } from 'axios'
import axios from '../instance'
import { GetShopQueryParams, ShopKyc, ShopResponse, IPayloadAddBooking, AdjustRatePayload, updateStatusPayload } from './shop.type'
import { ShopActionTypes } from '@/store/modules/shop/actions'
import { SHOP_TYPE } from '@/type/shop'

export const getShopList = async (params: GetShopQueryParams): Promise<AxiosResponse<ShopResponse>> => {
	try {
		const response = await axios.get('/admin/shop', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getShopDetailById = async (shopId: string): Promise<AxiosResponse<ShopDetailI>> => {
	try {
		const response = await axios.get(`/shop/detail/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getKycShopByShopId = async (shopId: string): Promise<AxiosResponse<ShopKyc>> => {
	try {
		const response = await axios.get(`/shop/kyc/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateKyc = async (shopId: string, payload: IPayloadAddBooking, remarkIssue: string[] = []): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.put(`/admin/shop/kyc/${shopId}`, {
			...payload,
			remarkIssue
		})
		// Try to hack Async Update Store KycDetail
		store.dispatch(ShopActionTypes.GET_SHOP_KYC_DETAIL, shopId)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getShopRate = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/admin/shop/get-rate/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const adjustShopRate = async (payload: AdjustRatePayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/admin/shop/adjust-rate', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getShopInbox = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/admin/shop-inbox/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteShopInbox = async (inboxId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.delete(`/admin/shop-inbox/delete/${inboxId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateActiveStatusShop = async (shopId: string, payload: updateStatusPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(`/admin/shop/${shopId}/update-status`, payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const adjustKYCShop = async (shopId: string, payload: any): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(`/admin/shop/adjust-kyc/${shopId}`, payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const sendKycEmail = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(`/admin/shop/${shopId}/send-quotation`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getShopSetting = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/admin/shop/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getUserRoles = async (): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/user/role')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const generateInviteUserLink = async (shopId: string, roleId: string, isSystemAdmin?: boolean): Promise<any> => {
	const { data } = await axios.post('/invite-user/generate-link', { shopId, roleId, isSystemAdmin })
	return data
}

export const getSearchUser = async (search: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/user', { params: { search } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getUserInShop = async (shopId: string): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get(`/shop/user/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface InvitePayload {
	shopId: string;
	userId: string;
	roleId: string;
}

export const inviteUserToShop = async (payload: InvitePayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/invite/user', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface RevokeUserPayloadI {
	shopId: string;
	userId: string;
}

export const revokeUserFromShop = async (payload: RevokeUserPayloadI): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/revoke/user', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getRelateCheckingShop = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/checking-approve-kyc', { params: { shopId } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateLimitOrderShop = async (payload: { shopId: string, limitTransferOrder: number}): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/admin/update-limit-transfer-order', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface ShopPayload { shopId: string, limitTransferOrder: number, referralCode: string ,averageCostOrder: number, shopType: SHOP_TYPE, enableMarketplace: boolean }

export const updateShopSetting = async (payload: ShopPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/admin/setting-shop', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const topupCredit = async (shopId: string, creditAmount: number): Promise<AxiosResponse<{data:{updateStatus:string},status:number,statusText:string}>> => {
	try {
		const response = await axios.put('/admin/shop/add-credit', {
			shopId,
			amount: Number(creditAmount)
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const reduceCredit = async (shopId: string, creditAmount: number): Promise<AxiosResponse<{data:{updateStatus:string},status:number,statusText:string}>> => {
	try {
		const response = await axios.put('/admin/shop/reduce-credit', {
			shopId,
			amount: Number(creditAmount)
		})
		
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const updateSupportAdminShop = async (shopId: string) => {
	try {
		const response = await axios.put('/admin/shop/update-admin-support-status', {
			shopId
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

interface ITransactionCredit {
	shopId: string
	offset: number
	limit: number
}

export const getTransactionShop = async (params: ITransactionCredit) => {
	try {
		const { data } = await axios.get(`/admin/transaction-credit/${params.shopId}`, {
			params
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const approveTransaction = async (transactionId: string) => {
	try {
		const { data } = await axios.post(`/admin/approve-transaction-credit/${transactionId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getAddressList = async (
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/address/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getBillPayment = async (
	shopId: string
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/shop/bill-payment/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getDashboardShop = async (
	shopId: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get(`/shop/dashboard/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const shopList = async (): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.get('/shop')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getStatisticShop = async (shopId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/parcel-statistics', { params: { shopId } })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export enum COD_PERIOD_TYPE {
	T1 = 'T1',
	T3 = 'T3',
	T5 = 'T5',
	PERIOD = 'PERIOD',
  }
  

export type AdjustCODPeriodTypePayload = {
	codPeriodType: COD_PERIOD_TYPE,
	courier: string,
	shopId: string
}

export const adjustCODPeriodType = async (payload: AdjustCODPeriodTypePayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/shop/adjust-cod-period-type', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}